import $ from 'jquery';
import jQuery from 'jquery';
$(document).ready(function()
	{
	$('.bizthumbnail').showLargeImage();
	$('.moreinfo').hideInfo();
	$('[rel~=tooltip]').showAbbr();
	});

$(function()
	{
	$('.hidefield').each(function()
		{
		var ctrl = $(this).find('input:last');
		var targetid = ctrl.attr('id');
		var target = $('.'+targetid);
		ctrl.click(function()
			{
			target.slideToggle('slow', function()
				{
				if (target.is(':hidden'))
					target.find('.requiredonshow').removeAttr('required');
				else
					target.find('.requiredonshow').attr('required', '');
				});
			return true;
			});
		target.find('input,textarea,select').filter('[required]').each(function()
			{
			$(this).addClass('requiredonshow');
			});
		if ( ! ctrl.is(':checked'))
			{
			target.hide();
			target.find('.requiredonshow').removeAttr('required');
			}
		});
	});

$(function()
	{
	$('a[href*="#"]').not('.top-bar a').not('.accordion a').not('.tabs a').not('.orbit-container a').not('.reveal-link').not('.clearing-assembled a').click(function()
		{
		var adjustOffset = 40;
		//var adjustOffset = 0;
		if (this.hash.length == 0)
			{
			var targetOffset = - adjustOffset;
			$('html,body').animate({scrollTop: targetOffset}, 1000);
			return false;
			}
		var $target = $(this.hash);
		$target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
		if ($target.length)
			{
			var targetOffset = $target.offset().top - adjustOffset;
			$('html,body').animate({scrollTop: targetOffset}, 1000);
			return false;
			}
		});
	});

(function($)
	{
	$.fn.hideInfo = function()
		{
		return this.each(function()
			{
			var self = $(this);
			var showtext = 'More info...';
			var hidetext = 'Hide info...';
			var title = self.attr('title');
			if (title)
				{
				showtext = 'Show ' + title;
				hidetext = 'Hide ' + title;
				}
			var lnk = $('<a class="minfo" href="#">' + showtext + '</a>');
			lnk.click(function()
				{
			  self.slideToggle('slow', function()
					{
					if ($(this).is(":visible"))
						lnk.text(hidetext);
			    else
						lnk.text(showtext);
					//setTall();
			  	});
				return false;
				});
			var p = $('<p/>');
			p.append(lnk);
			p.insertAfter(self);
			self.hide();
			});
		}
	})(jQuery);

(function($)
	{
	$.fn.showLargeImage = function()
		{
		$(document).keypress(function(e)
			{
			if(e.keyCode==27)  
				removeLightbox();
			});
		return this.each(function()
			{
			var a = $('<a class="lightbox"/>');
			var src = $(this).attr('src');
			var dot = src.lastIndexOf('.');
			src = src.substr(0, dot) + '-large' + src.substr(dot);
			a.attr('href', src);
			a.click(function(e)
				{
				$('body').css('overflow-y', 'hidden');

				$('<div id="overlay"></div>')
					.css('top', $(document).scrollTop())
					.css('opacity', '0')
					.animate({'opacity': '0.5'}, 'slow')
					.appendTo('body');

				$('<div id="lightbox"></div>')
					.hide()
					.appendTo('body');

				$('<img title="Click here to close"/>')
					.attr('src', $(this).attr('href'))
					.on('load', function()
						{
						positionLightboxImage();
						})
					.on('click', function()
						{
						removeLightbox();
						})
					.appendTo('#lightbox');

				return false;
				});
			$(this).wrap(a);
			});
		function positionLightboxImage()
			{
			var top = ($(window).height() - $('#lightbox').height()) / 2;
			var left = ($(window).width() - $('#lightbox').width()) / 2;
			var closebutton = $('<img src="/images/close.png" width="30" height="30" alt="close" style="position: absolute; top: 0px; right: 0px;"/>');
			closebutton.click(function()
				{
				removeLightbox();
				});
			$('#lightbox')
				.append(closebutton)
				.css({'top': top + $(document).scrollTop(), 'left': left})
				.fadeIn();
			}
		function removeLightbox()
			{
			$('#overlay, #lightbox')
				.fadeOut('slow', function()
					{
					$(this).remove();
					$('body').css('overflow-y', 'auto');
					});
			}
		}
	})(jQuery);

(function($)
	{
	$.fn.showAbbr = function()
		{
		var $target    = false,
		    $tooltip   = false,
		    tip       = false,
		    reqScroll = false,
		    timer     = 0,
		    delay     = 250,
		    isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
		return this.each(function()
			{
			$(this).bind('mouseenter', function()
				{
				if ($target)
					remove_tooltip();
				$target    = $(this);
				tip       = $target.attr( 'title' );
				$tooltip = $( '<div id="tooltip"></div>' );

				if ( !tip || tip == '' )
					return false;

				var scripture = tip.replace(/\[/g, '<sup>').replace(/\]/g, '</sup>');
 
				$target.removeAttr( 'title' );
				$tooltip.css( 'opacity', 0 )
				       .html( scripture )
				       .appendTo( 'body' );
				init_tooltip();
				$(window).resize( init_tooltip );
				$target.bind( 'mouseleave', remove_tooltip );
				$tooltip.bind( 'click', remove_tooltip );
				});
			});
		function init_tooltip()
			{
			var pos_left = 10;
			if ( $(window).width() > 480 )
				$tooltip.css( 'max-width', $( window ).width() / 2 );
			else
				$tooltip.css( 'max-width', $(window).width() - 20 );

			var pos_top  = $target.offset().top + $target.outerHeight() + 12;
			$tooltip.addClass( 'top' );
			reqScroll = (pos_top + $tooltip.outerHeight() > $(window).scrollTop() + $(window).height());
			
			if ( $(window).width() > 480 )
				{
				pos_left = $target.offset().left + ( $target.outerWidth() / 2 ) - ( $tooltip.outerWidth() / 2 );

				if ( pos_left < 0 )
					{
					pos_left = $target.offset().left + $target.outerWidth() / 2 - 20;
					$tooltip.addClass( 'left' );
					}
				else
					$tooltip.removeClass( 'left' );
 
				if ( pos_left + $tooltip.outerWidth() > $( window ).width() )
					{
					pos_left = $target.offset().left - $tooltip.outerWidth() + $target.outerWidth() / 2 + 20;
					$tooltip.addClass( 'right' );
					}
				else
					$tooltip.removeClass( 'right' );
				}

			if (isTouch)
				$tooltip.css( { left: pos_left, top: pos_top } ).animate( {opacity: 1 }, 50 );
			else
				timer = setTimeout(function()
					{
					if (reqScroll)
						$target.unbind('mouseleave');
					$tooltip.css( { left: pos_left, top: pos_top } ).animate( {opacity: 1 }, 50 );
					}, delay);
			}
		function remove_tooltip()
			{
			clearTimeout(timer);
			$tooltip.animate( { opacity: 0 }, 50, function()
				{
				$(this).remove();
				});
			$target.attr( 'title', tip );
			$target.unbind('mouseleave');
			}
		}
	})(jQuery);
