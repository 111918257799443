import $ from 'jquery';
import jQuery from 'jquery';

$(function()
	{
	function showForm(type)
		{
		if (type == 'credit')
			{
			$('.bizCreditPayment').show('slow');
			$('.requiredonshow').attr('required', '');
			}
		else
			{
			$('.bizCreditPayment').hide('slow');
			$('.requiredonshow').removeAttr('required');
			}
		if ( ! type)
			$('.bizSubmitPayment').hide('slow');
		else
			$('.bizSubmitPayment').show('slow');
		}

	$('.bizPaymentOption').on('click', function()
		{
		showForm($(this).data('type'));
		});

	//showForm($('.bizPaymentOption:checked').data('type')); // comment out if cc only
	$('.bizCreditPayment').show(); // comment out if cc and other payment options
	$('.requiredonshow').attr('required', '');

	$('#paymentsubmit').on('click', function()
		{
		$(this).attr('disabled', true);
		//$(window).bind('beforeunload', function()
		//	{
		//	console.log('beforeunload');
		//	return 'Payment is being processed, please wait to receive your invoice';
		//	});
		$('#lightboxwait').show();
		});
	});
 

